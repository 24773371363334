/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../../components/organisms/Table';
import { thDataDis, thData } from './tableData';
import './PreTAS-Table.css';
import { GET_MASTER_TEMPLATES, GET_PRE_TAS, PRE_TAS_UPLOAD, UPDATE_PRE_TAS } from '../../../../constants/actionType';
/**
 *
 * @returns {React.ReactElement} - returns the IDA input screen
 */
const PreTASTableInput = (props) => {
  const { testCancel, jsonData, fieldValue, isJson, setIsJson } = props;
  const vals = [
    'year',
    'block',
    'random',
    'state_id',
    'pre_tas_id',
    'district_id',
    'state_code',
    'strategy_type_id',
    'strategy_type',
    'block_strategy_id',
    'pre tas sites blocks id',
    'proposed_program_activities',
    'created_by',
    'updated_by',
    'BSE R6',
    'created_on',
    'updated_on',
    'pre_tas_status',
    'pre_tas_sites_blocks_id1',
    'pre_tas_sites_blocks_id2',
    'pre_tas_sites_blocks_id3',
    'pre_tas_sites_blocks_id4',
    'pre_tas_sites_blocks_id5',
    'pre_tas_sites_blocks_id6',
    'pre_tas_sites_blocks_id7',
    'block_count',
    'endemic_population',
    'mail_status',
    'mail_date',
    'test_type_id',

    'Covered Population',
  ];
  const vals1 = [
    'year',
    'block',
    'random',
    'state_id',
    'pre_tas_id',
    'district_id',
    'state_code',
    'strategy_type_id',
    'strategy_type',
    'block_strategy_id',
    'pre tas sites blocks id',
    'proposed_program_activities',
    'created_by',
    'updated_by',
    'BSE R6',
    'created_on',
    'updated_on',
    'pre_tas_status',
    'pre_tas_sites_blocks_id1',
    'pre_tas_sites_blocks_id2',
    'pre_tas_sites_blocks_id3',
    'pre_tas_sites_blocks_id4',
    'pre_tas_sites_blocks_id5',
    'pre_tas_sites_blocks_id6',
    'pre_tas_sites_blocks_id7',
    'block_count',
    'endemic_population',
    'population',
    'mail_status',
    'mail_date',
    'test_type_id',
    // 'test_type',
    // 'pre_tas_survey_date',
  ];
  React.useEffect(() => {
    if (fieldValue !== 'Block Strategy') {
      vals.push('population');
    }
  }, [fieldValue]);
  const dispatch = useDispatch();
  // const [tablehead, setTableHead] = useState();
  const [refresh, setRefresh] = useState(false);
  const { getPreTas } = useSelector((state) => state?.preTas);
  // useEffect(() => {
  //   if (fieldValue === 'District Strategy') {
  //     setTableHead(thDataDis);
  //   } else {
  //     setTableHead(thDataBlk);
  //   }
  // }, [fieldValue]);
  useEffect(() => {
    dispatch({ type: GET_PRE_TAS, payload: { strategy: fieldValue } });
  }, [dispatch]);
  
  const tableElements = React.useMemo(() => {
    return getPreTas.map((data) => {
      let sitesRow = {};
      let sitesBlocks = []; 
  
      data.preTasDetails?.forEach((item, index) => {
        const i = index + 1;
  
        if (fieldValue === 'District Strategy') {
          sitesRow = {
            ...sitesRow,
            [`Block Name${i}`]: item.block_name,
            [`R${i} Name`]: item.random_name,
            [`BSE R${i}`]: item.random_blood_count,
            [`Positive R${i}`]: item.random_possitive,
          };
        }
  
        if (fieldValue === 'Block Strategy') {
          item?.preTasBlocks.forEach((val) => {
            const blockData = {
              state: data.state,
              district: data.district,
              strategy: data.block_strategy,
              date_planned: data.date_planned,
              date_completed: data.date_completed,
              blockname: item.block_name,
              population: val.population,
              test_type: item.test_type,
              pre_tas_survey_date: item.pre_tas_survey_date,
              random_name: val.random_name,
              random_blood_count: val.random_blood_count,
              random_possitive: val.random_possitive,
            };
            sitesBlocks.push(blockData); 
          });
        }
      });
  
      delete data.preTasDetails;
  
      if (fieldValue === 'Block Strategy') {
        return sitesBlocks; 
      } else if (fieldValue === 'District Strategy') {
        return { ...data, ...sitesRow }; 
      }
  
      return null;
    }).flat(); 
  }, [getPreTas, refresh]);
  /**
   *
   * @returns
   */
  const getPreTasUploadDistrict = (jsonData) => {
    const arr1 = [];
    jsonData?.map((item) => {
      const arr = [];
      for (let i = 1; i <= item?.random; i += 1) {
        arr.push({
          block_name: item[`Block Name${i}`],
          random_name: item[`R${i} Name`],
          random_blood_count: item[`BSE R${i}`],
          random_possitive: item[`Positive R${i}`],
          site_id: `R${i}`,
          mf_rate: 1,
        });
      }
      arr1.push({
        proposed_program_activities: 'Pre-TAS',
        state_id: item?.state,
        district_id: item?.district,
        block_strategy_id: item?.block_strategy,
        random: item?.random,
        block_count: 0,
        date_proposed: moment(item.pre_tas_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        date_proposed: moment(item.date_proposed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        date_planned: moment(item.date_planned, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        date_completed: moment(item.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        test_type_id: item?.test_type,
        date_planned: moment(item.pre_tas_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        population: item?.population,
        preTasDistrictSurvey: arr,
      });
    });
    return arr1;
  };
  /**
   *
   * @returns
   */
  
  const getPretasUploadBlock = (jsonData) => {
    const finalObj = [];
    const districtMap = new Map();
  
    jsonData?.forEach((item) => {
      const blockSurvey = {
        block_name: item["Block Name"] || '',
        total_population: Number(item["Total population"]) || 0,
        test_type_id: item["Test Type"],
        pre_tas_survey_date: moment(item["Survey Date"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        block_survey: [
          {
            site_id: 'R1',
            random_name: item["Site Name"] || '',
            random_blood_count: Number(item["BSE"]) || 0,
            random_possitive: Number(item["Possitive"]) || 0,
          },
        ],
      };
  
      const districtKey = item.State + item.District;
      if (districtMap.has(districtKey)) {
        const districtData = districtMap.get(districtKey);
        districtData.population += Number(item["Total population"]) || 0; 
        districtData.block_count += 1; 
        districtData.preTasBlockSurvey.push(blockSurvey);
      } else {
        districtMap.set(districtKey, {
          proposed_program_activities: 'Pre-TAS',
          state_id: item.State,
          district_id: item.District,
          block_strategy_id: item.Strategy,
          date_planned: moment(item["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          date_completed: moment(item["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          population: Number(item["Total population"]) || 0,
          block_count: 1, 
          preTasBlockSurvey: [blockSurvey], 
        });
      }
    });
  
    districtMap.forEach((value) => finalObj.push(value));
    return finalObj;
  };
  
  /**
   * @param {string} key update key
   * @param {Array} EditedItems edited row items
   */
  const onSendUpdatedValue = (key, EditedItems) => {
    const updatedItems = EditedItems.map((entry) => {
      const preTasSites = [...Array(entry.random).keys()].map((val) => {
        const count = val + 1;
        setRefresh(!refresh);
        return {
          site_id: '10',
          total_population: entry.population,
          block_name: entry[`Block Name${count}`],
          pre_tas_sites_blocks_id: entry[`pre_tas_sites_blocks_id${count}`] || null,
          test_type: entry.test_type || null,
          pre_tas_survey_date: entry.NBS_Survey_Date || null,
          random_name: entry[`R${count} Name`] || null,
          random_blood_count: entry[`BSE R${count}`] || null,
          random_possitive: entry[`Positive R${count}`] || null,
          mf_rate: '12',
        };
      });
      return {
        population: entry.population,
        block_count: 10,
        proposed_program_activities: 'Pre-Tas',
        state: entry.state,
        district: entry.district,
        block_strategy: entry.block_strategy,
        block: entry.block,
        random: entry.random,
        date_proposed: entry.date_proposed,
        date_planned: entry.date_planned,
        date_completed: entry.date_completed,
        pre_tas_id: entry.pre_tas_id,
        preTasSurvey: preTasSites,
      };
    });

  
    if (jsonData?.length > 0) {
      if (fieldValue =="District Strategy"){
        dispatch({ type: PRE_TAS_UPLOAD, payload: { data: getPreTasUploadDistrict(jsonData), strategy: jsonData?.[0]?.block_strategy}});
      } else {
          dispatch({ type: PRE_TAS_UPLOAD, payload: { data: getPretasUploadBlock(jsonData),  strategy: jsonData?.[0]?.Strategy } });
          }
      dispatch({ type: GET_MASTER_TEMPLATES });
    } else {
      dispatch({
        type: UPDATE_PRE_TAS,
        payload: updatedItems,
      });
    }
    setTimeout(() => {
      dispatch({ type: GET_PRE_TAS, payload: { strategy: fieldValue } });
      setIsJson(false);
    }, 500);
  };

  return (
    <Table
      header={fieldValue === 'Block Strategy' ? thData : thDataDis}
      rows={isJson ? jsonData : tableElements}
      slideData
      showSno
      inputTable
      Activity="PreTas"
      onCancel={() => testCancel()}
      onSendUpdatedData={(key, rowData) => onSendUpdatedValue(key, rowData)}
      uniqueKey="pre_tas_id"
      hideKeyFields={fieldValue === 'Block Strategy' ? vals : vals1}
      IsExcelData={isJson}
      screen="input"
    />
  );
};
export default PreTASTableInput;

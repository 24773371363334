/* eslint-disable default-param-last */
import {
  GET_TAS_RESULT,
  GET_TAS1_PROPOSED_RESULT,
  GET_TAS2_PROPOSED_RESULT,
  GET_TAS3_PROPOSED_RESULT,
  LOADER,
  INSERT_TAS_STATUS,
  INSERT_TAS_DISRICT_STRATEGY_STATUS,
  INSERT_TAS_BLOCK_STRATEGY_STATUS,
  BULKUPLOAD_TAS_RESULT,
} from '../../constants/actionType/index';
import { TASInfo } from '../initialStates/index';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const insertTAS1Reducer = (state = TASInfo, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADER:
      return {
        ...state,
        loading: payload,
      };
    case INSERT_TAS_STATUS:
      return {
        ...state,
        status: payload,
        UploadError: payload.data,
        loading: false,
      };
    case INSERT_TAS_DISRICT_STRATEGY_STATUS:
      return {
        ...state,
        TASInsertDistrictData: payload,
        loading: false,
      };
    case INSERT_TAS_BLOCK_STRATEGY_STATUS:
      return {
        ...state,
        TASInsertBlockData: payload,
        loading: false,
      };
    case GET_TAS_RESULT:
      return {
        ...state,
        TASGetData: payload.data,
        loading: false,
      };
    case GET_TAS1_PROPOSED_RESULT:
      return {
        ...state,
        getProposed1Data: payload.data,
        loading: false,
      };
    case GET_TAS2_PROPOSED_RESULT:
      return {
        ...state,
        getProposed2Data: payload.data,
        loading: false,
      };
    case GET_TAS3_PROPOSED_RESULT:
      return {
        ...state,
        getProposed3Data: payload.data,
        loading: false,
      };
    case BULKUPLOAD_TAS_RESULT:
      return {
        ...state,
        bulkUploadTas: payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
export default insertTAS1Reducer;

/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Dropdown from '../../atoms/Dropdown/dropdown';
import FileFormat from '../../atoms/FileFormat';
import CustomIcons from '../../../utils/icons';
import './styles.css';

/**
 * @name FileGenerator
 * @param {object} props required props
 * @returns {React.ReactElement} -- returns the file generator with dropdowns
 */
const FileGenerator = (props) => {
  const {
    getSelectValue,
    DropdownList,
    onClickWordDownload,
    onClickExcelDownload,
    onClickPdfDownload,
    showExporter,
    districtDropdown,
    getDistrictList,
    handleUpdate,
    blockDropdown,
    DropdownSelectedValues,
    fileData,
    title,
    status,
  } = props;
  const dropdownList = useSelector((state) => state.dropDownReducer);
  const [isEdit, setEdit] = React.useState(false);

  const yearRange = [];
  for (let i = 0; i <= new Date().getFullYear() - 2004; i += 1) {
    const year = 2004 + i;
    yearRange.push({ id: i + 1, value: year.toString() });
  }

  const countryProfileActivity = [
    { id: 'Under DA', type: 'Under', value: 'Pending DA' },
    { id: 'Under IDA', type: 'Under', value: 'Pending IDA' },
    { id: 'DA Pass', type: 'Pass', value: 'DA Pass' },
    { id: 'DA Fail', type: 'Fail', value: 'DA Fail' },
    { id: 'IDA Pass', type: 'Pass', value: 'IDA Pass' },
    { id: 'IDA Fail', type: 'Fail', value: 'IDA Fail' },
    { id: 'Under Pre TAS', type: 'Under', value: 'Pending Pre TAS' },
    { id: 'Pre TAS Pass', type: 'Pass', value: 'Pre TAS Pass' },
    { id: 'Pre TAS Fail', type: 'Fail', value: 'Pre TAS Fail' },
    { id: 'TAS 1 Pass', type: 'Pass', value: 'TAS 1 Pass' },
    { id: 'TAS 1 Fail', type: 'Fail', value: 'TAS 1 Fail' },
    { id: 'TAS 2 Pass', type: 'Pass', value: 'TAS 2 Pass' },
    { id: 'TAS 2 Fail', type: 'Fail', value: 'TAS 2 Fail' },
    { id: 'TAS 3 Pass', type: 'Pass', value: 'TAS 3 Pass' },
    { id: 'TAS 3 Fail', type: 'Fail', value: 'TAS 3 Fail' },
    { id: 'Under TAS 1', type: 'Under', value: 'Pending TAS 1' },
    { id: 'Under TAS 2', type: 'Under', value: 'Pending TAS 2' },
    { id: 'Under TAS 3', type: 'Under', value: 'Pending TAS 3' },
    // { id: 'PMS1', type: 'Under', value: 'PMS1' },
    // { id: 'PMS2', type: 'Under', value: 'PMS2' },
    // { id: 'PMS3', type: 'Under', value: 'PMS3' },
  ];

  const ActivityPrediction = [
    { id: 'DA', type: 'Completed', value: 'Pending DA' },
    { id: 'IDA', type: 'Completed', value: 'Pending IDA' },
    { id: 'Pre TAS', type: 'Completed', value: 'Pending Pre TAS' },
    { id: 'TAS 1', type: 'Completed', value: 'Pending TAS 1' },
    { id: 'TAS 2', type: 'Completed', value: 'Pending TAS 2' },
    { id: 'TAS 3', type: 'Completed', value: 'Pending TAS 3' },
    { id: 'PMS1', type: 'Under', value: 'PMS1' },
    { id: 'PMS2', type: 'Under', value: 'PMS2' },
    { id: 'PMS3', type: 'Under', value: 'PMS3' },
  ];
  const [AllDropDownList, setAllDropDownList] = React.useState([
    {
      placeholder: 'Year',
      value: '',
      list: yearRange,
    },
    {
      placeholder: 'Month',
      value: '',
      list: [
        { id: 1, value: 'January' },
        { id: 2, value: 'February' },
        { id: 3, value: 'March' },
        { id: 4, value: 'April' },
        { id: 5, value: 'May' },
        { id: 6, value: 'June' },
        { id: 7, value: 'July' },
        { id: 8, value: 'August' },
        { id: 9, value: 'September' },
        { id: 10, value: 'October' },
        { id: 11, value: 'November' },
        { id: 12, value: 'December' },
      ],
    },
    {
      placeholder: 'State',
      value: '',
      list: dropdownList.stateData,
    },
    {
      placeholder: 'District',
      value: '',
    },
    {
      placeholder: 'Activity',
      value: '',
      list: dropdownList.activityType,
    },
    {
      placeholder: 'Activity_MDA',
      value: '',
      list: [],
    },
    {
      placeholder: 'Activity_PreTAS_TAS',
      value: '',
      list: [],
    },
    {
      placeholder: 'Status',
      value: '',
      list:
        status === 'CustomReport'
          ? [
              { id: 1, value: 'Passed' },
              { id: 2, value: 'Failed' },
              { id: 3, value: 'On-Going' },
            ]
          : [
              { id: 1, value: 'Completed' },
              { id: 2, value: 'Pending' },
              { id: 3, value: 'Delayed' },
            ],
    },
    {
      placeholder: 'Sort',
      value: '',
      list: [
        { id: 1, value: 'A-Z' },
        { id: 2, value: 'Z-A' },
      ],
    },
    {
      placeholder: 'Strategy',
      value: '',
      list: dropdownList.strategy,
    },
    {
      placeholder: 'Activity Type',
      value: '',
      list: [
        { id: 1, value: 'Conducted' },
        { id: 2, value: 'Delayed' },
        { id: 3, value: 'Pending' },
        { id: 4, value: 'Ongoing' },
      ],
    },
    {
      placeholder: 'Data Status',
      value: '',
      list: dropdownList.activityType,
    },
    {
      placeholder: 'Reports',
      value: '',
      list: blockDropdown,
    },
    {
      placeholder: 'Site',
      value: '',
    },
    {
      placeholder: 'Activity_TAS',
      value: '',
      list: [],
    },
    {
      placeholder: 'Calendar Status',
      value: '',
      list: [
        { id: 1, value: 'Delayed' },
        // { id: 2, value: 'Proposed' },
        // { id: 3, value: 'Pending' },
        { id: 4, value: 'Completed' },
      ],
    },
    {
      placeholder: 'Country Profile Activity',
      value: '',
      list: countryProfileActivity,
    },
    {
      placeholder: 'Country Profile Status',
      value: '',
      list: [
        { id: 'Under', value: 'Pending' },
        { id: 'Pass', value: 'Pass' },
        { id: 'Fail', value: 'Fail' },
      ],
    },
    {
      placeholder: 'Country Profile Under Activity',
      value: '',
      // eslint-disable-next-line no-use-before-define
      // list: onChangeHandle({ fieldName: 'Country Profile Status', value: 'Under' }),
      list: [
        { id: 'Under DA', type: 'Under', value: 'Pending DA' },
        { id: 'Under IDA', type: 'Under', value: 'Pending IDA' },
        // { id: 'DA Completed', type: 'Completed', value: 'DA Completed' },
        // { id: 'IDA Completed', type: 'Completed', value: 'IDA Completed' },
        { id: 'Under Pre TAS', type: 'Under', value: 'Pending Pre TAS' },
        // { id: 'Pre TAS Completed', type: 'Completed', value: 'Pre TAS Completed' },
        // { id: 'TAS 1 Completed', type: 'Completed', value: 'TAS 1 Completed' },
        // { id: 'TAS 2 Completed', type: 'Completed', value: 'TAS 2 Completed' },
        // { id: 'TAS 3 Completed', type: 'Completed', value: 'TAS 3 Completed' },
        { id: 'Under TAS 1', type: 'Under', value: 'Pending TAS 1' },
        { id: 'Under TAS 2', type: 'Under', value: 'Pending TAS 2' },
        { id: 'Under TAS 3', type: 'Under', value: 'Pending TAS 3' },
        { id: 'PMS1', type: 'Under', value: 'PMS1' },
        { id: 'PMS2', type: 'Under', value: 'PMS2' },
        { id: 'PMS3', type: 'Under', value: 'PMS3' },
      ],
    },
    {
      placeholder: 'Activity_Prediction',
      value: '',
      list: ActivityPrediction,
    },
  ]);

  React.useMemo(() => {
    if (dropdownList?.activityType && dropdownList?.activityType.length > 0) {
      const MDA = dropdownList?.activityType.filter((data) => {
        return data.value === 'DA' || data.value === 'IDA';
      });
      AllDropDownList[5].list = MDA;
      const PRETAS_TAS = dropdownList?.activityType.filter((data) => {
        return data.value !== 'DA' && data.value !== 'IDA';
      });
      AllDropDownList[6].list = PRETAS_TAS;
      const TAS = dropdownList?.activityType.filter((data) => {
        return data.value === 'TAS1' || data.value === 'TAS2' || data.value === 'TAS3';
      });
      AllDropDownList[14].list = TAS;
    }
  }, [dropdownList?.activityType]);

  React.useMemo(() => {
    AllDropDownList[2].list = dropdownList.stateData;
    AllDropDownList[4].list = dropdownList.activityType;
    AllDropDownList[9].list = dropdownList.strategy;
  }, [dropdownList]);

  React.useMemo(() => {
    AllDropDownList[12].list = blockDropdown;
  }, [blockDropdown]);

  /**
   *
   * @param {object} obj obj
   */
  const onChangeHandle = (obj) => {
    getSelectValue(obj);
    if (obj.fieldName === 'Country Profile Status') {
      const index = 16;
      if (obj.value !== '') {
        const filterData = countryProfileActivity.filter((data) => data.type === obj.value);
        const newState = [...AllDropDownList];
        newState[index].list = filterData;
        setAllDropDownList(newState);
      } else {
        const newState = [...AllDropDownList];
        newState[index].list = countryProfileActivity;
        setAllDropDownList(newState);
      }
    }
    if (obj.fieldName === 'State') {
      const stateId = dropdownList.stateData.find((data) => data.value === obj.value).id;
      getDistrictList(stateId);
    }
    if (obj.fieldName === 'Reports') {
      const { id } = blockDropdown.find((data) => data.value === obj.value);
      getDistrictList(id);
    }
  };

  return (
    <Grid item md={12} xs={12} sm={12} container direction="row" className="outerGrid">
      <Grid item md={showExporter ? 11 : 12} xs={12} sm={12} container spacing={2} direction="row">
        {DropdownList &&
          DropdownList.length > 0 &&
          AllDropDownList.map((dropVal) => {
            if (DropdownList.includes(dropVal.placeholder))
              if (dropVal.placeholder === 'District' || dropVal.placeholder === 'Site')
                return (
                  <Grid item className="dropdownInput">
                    <Dropdown
                      dropdown={dropVal.placeholder === 'District' ? districtDropdown : blockDropdown}
                      value={
                        (DropdownSelectedValues[dropVal.placeholder] && DropdownSelectedValues[dropVal.placeholder]) ||
                        dropVal.value
                      }
                      onChangeData={(data) => {
                        onChangeHandle({ fieldName: dropVal.placeholder, value: data });
                      }}
                      className="xs-dropdown"
                      placeholder={dropVal.placeholder}
                      disabled={dropVal.disable}
                    />
                  </Grid>
                );
              else
                return (
                  <Grid item className="dropdownInput">
                    <Dropdown
                      dropdown={dropVal.list}
                      value={
                        (DropdownSelectedValues[dropVal.placeholder] && DropdownSelectedValues[dropVal.placeholder]) ||
                        dropVal.value
                      }
                      onChangeData={(data) => {
                        onChangeHandle({ fieldName: dropVal.placeholder, value: data });
                      }}
                      className={`xs-dropdown ${dropVal.placeholder === 'Reports' && 'fullwidth'}`}
                      placeholder={
                        dropVal.placeholder === 'Activity_MDA' ||
                        dropVal.placeholder === 'Activity_TAS' ||
                        dropVal.placeholder === 'Activity_PreTAS_TAS'
                          ? 'Activity'
                          : dropVal.placeholder
                      }
                      disabled={dropVal.disable}
                    />
                  </Grid>
                );
            return null;
          })}
      </Grid>
      {showExporter && (
        <Grid
          item
          md={1}
          xs={12}
          sm={12}
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'end',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          }}
        >
          <div className="edit-grid">
            {/* <img
              src={isEdit ? CustomIcons.Update : CustomIcons.Edit}
              alt="word"
              onClick={() => {
                setEdit(!isEdit);
                handleUpdate(isEdit);
              }}
              className="editPencil"
            /> */}
          </div>
          <FileFormat fileData={fileData} title={title} />
        </Grid>
      )}
    </Grid>
  );
};
export default FileGenerator;
FileGenerator.propTypes = {
  DropdownList: PropTypes.array,
  getSelectValue: PropTypes.func,
  onClickWordDownload: PropTypes.func,
  onClickExcelDownload: PropTypes.func,
  onClickPdfDownload: PropTypes.func,
  showExporter: PropTypes.bool,
  districtDropdown: PropTypes.objectOf(),
  getDistrictList: PropTypes.func,
  handleUpdate: PropTypes.func,
  blockDropdown: PropTypes.array,
  fileData: PropTypes.array,
  DropdownSelectedValues: PropTypes.objectOf(),
  title: PropTypes.string,
  status: PropTypes.string,
};
FileGenerator.defaultProps = {
  getSelectValue: () => {},
  onClickWordDownload: () => {},
  onClickExcelDownload: () => {},
  onClickPdfDownload: () => {},
  showExporter: true,
  DropdownList: [],
  districtDropdown: null,
  blockDropdown: [],
  getDistrictList: () => {},
  handleUpdate: () => {},
  DropdownSelectedValues: {},
  fileData: [],
  title: null,
  status: '',
};

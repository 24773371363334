
export const FormEnteries = [
    // {
    //   count: 'true',
    //   entryName: 'state',
    //   placeholder: 'State',
    //   dropdown: true,
    //   data: [
    //     { id: 1, value: 'Kerala' },
    //     { id: 2, value: 'Goa' },
    //     { id: 3, value: 'TamilNadu' },
    //   ],
    //   validation: [{ isRequired: true }],
    // },
    // {
    //   count: 'true',
    //   entryName: 'district',
    //   placeholder: 'District',
    //   disabled: true,
    //   dropdown: true,
    //   data: [
    //     { id: 1, value: 'Kerala' },
    //     { id: 2, value: 'Goa' },
    //     { id: 3, value: 'TamilNadu' },
    //   ],
    //   validation: [{ isRequired: true }],
    // },
    // {
    //   count: 'true',
    //   entryName: 'drug_regime',
    //   placeholder: 'Drug Regime',
    //   dropdown: true,
    //   data: [
    //     { id: 1, value: 'DA' },
    //     { id: 2, value: 'IDA' },
    //   ],
    //   validation: [{ isRequired: true }],
    // },
    // {
    //   count: 'true',
    //   entryName: 'drug_type',
    //   placeholder: 'DrugType',
    //   dropdown: true,
    //   data: [
    //     { id: 1, value: 'DA' },
    //     { id: 2, value: 'IDA' },
    //   ],
    //   validation: [{ isRequired: true }],
    // },
    // {
    //   count: true,
    //   date: true,
    //   entryName: 'from_date',
    //   placeholder: 'From Date',
    //   disabled: false,
    //   validation: [{ isRequired: true }],
    // },
    {
        count: true,
        textField: true,
        entryName: 'state',
        placeholder: 'State',
        textInputType: 'default',
        validation: [{ isRequired: true }],
      },
      {
        count: true,
        textField: true,
        entryName: 'state_code',
        placeholder: 'State Code',
        textInputType: 'default',
        validation: [{ isRequired: true }],
      },

    {
      count: true,
      textField: true,
      entryName: 'year',
      placeholder: 'Year',
      textInputType: 'number',
      validation: [{ isRequired: true }],
    },
    // {
    //   count: 'true',
    //   radioButton: true,
    //   entryName: 'status',
    //   placeholder: 'Certificate',
    //   labelText: 'Status',
    //   radioData: [{ name: 'Active' }, { name: 'Inactive' }],
    //   validation: [{ isRequired: true }],
    // },
  ];
  export const formDefaultValues = {
    state: '',
    state_code:'',
    year:'',
    // district: '',
    // drug_type: '',
    // drug_regime: '',
    // from_date: '',
    // to_date: '',
    // status: 'Active',
  };
  
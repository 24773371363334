import React from 'react'
import { GET_STATE, GET_STATE_RESULT } from '../../constants/actionType';
import { districtState, stateSet } from '../initialStates';

const stateReducer = (state = stateSet, action) => {
    const { type, payload } = action;
      switch (type) {
      case GET_STATE:
        return {
          ...state,
          payload,
        };
      case GET_STATE_RESULT:
        return {
          ...state,
          getStateValue: payload.data,
        };
  
      default:
        return state;
    }
}

export default stateReducer
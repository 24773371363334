/* eslint-disable default-param-last */
import {
  IDA_DATA_RESULT,
  LOADER,
  IDA_STATUS,
  PROPOSED_DATE_IDA,
  UPLOAD_MDA_IDA_RESULT,
  INSERT_BLOCK_RESULT,
} from '../../constants/actionType';
import { idaInput } from '../initialStates';

/**
 * @param {string} state defines the state
 * @param {object} action used for payload and type
 * @returns {Array} action response state
 */
const IDAReducer = (state = idaInput, action) => {
  const { type, payload } = action;
  switch (type) {
    case IDA_DATA_RESULT:
      return {
        ...state,
        getIDA: payload.data,
        loading: false,
      };
    case LOADER:
      return {
        ...state,
        loading: payload,
      };
    case IDA_STATUS:
      return {
        ...state,
        status: payload,
        UploadError: payload.data,
        loading: false,
      };
    case PROPOSED_DATE_IDA:
      return {
        ...state,
        proposedDateIDA: payload.data,
        loading: false,
      };
    case UPLOAD_MDA_IDA_RESULT:
      return {
        ...state,
        uploadMdaIda: payload.data,
        loading: false,
      };
    case INSERT_BLOCK_RESULT:
      return {
        ...state,
        insertBlock: payload.data,
        loading: false,
      };
    default:
      return state;
  }
};
export default IDAReducer;

import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import './CreateDistrict.css';
import Table from './Table/index';
import Form from './CreateForm/index';
import { FormEnteries, formDefaultValues } from './CreateForm/FormEnteries';
import DatePicker from '../../../components/atoms/DatePicker';
import FileUpload from '../../../components/molecules/FileUpload';
import Typography from '../../../components/atoms/Typography/index';
import Toaster from '../../../components/atoms/ToastMessage';
import { GET_MASTER_TEMPLATES, BULK_UPLOAD_DRUG } from '../../../constants/actionType';
/**
 * @returns {React.ReactElement} return the user role component
 */
const District = () => {
    const [date, setDate] = React.useState(new Date());
    const [isFormEdited, setIsFormEdited] = React.useState(false);
    const [editableDefaultValue, setEditableDefaultValue] = React.useState({});
    const [file, setFile] = React.useState();
    const [showTable, setShowTable] = React.useState(false);
    const [isJson, setIsJson] = React.useState(false);
  
    const [toaster, setToaster] = React.useState(null);
    const [jsonData, setJsonData] = React.useState();
    const dispatch = useDispatch();
    const InputActionValidation = localStorage.getItem('ActionAccess');
    const Actionvalue = JSON.parse(InputActionValidation);
    const { BulkUpload, Download, Edit, ManualDataInput, View } = Actionvalue?.masters?.[0];
  
    const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
    const { status } = useSelector((state) => state?.drugTypeDetails);
    const [formControl, setFormControl] = React.useState();
    const ValidationGroup = localStorage.getItem('userGroupId');
    const ValidationType = localStorage.getItem('userTypeId');
    useEffect(() => {
        dispatch({ type: GET_MASTER_TEMPLATES });
      }, [dispatch]);
    /**
   *
   * @param {*} e -- Table Data
   * @param {number} ids All ids from the table
   * @returns {object} new defaultValues for edit option
   */
  const editableDefaultValues = (e, ids) => {
    setIsFormEdited(!isFormEdited);
    return setEditableDefaultValue({
      district_id: ids[e.S_no - 1],
      state: e.State_Id,
      district: e.District,
      year:e.Year,
    //   drug_regime: e.Drug_Reigme_Id,
    //   drug_type: e.Drug_Type_Id,
    //   from_date: e.From_Date,
    //   to_date: e.To_Date,
    //   status: e.Status,
    });
  };

  const onClickHandleView = () => {
    setShowTable(true);
    setIsJson(true);
  };

    /**
   * @param {*} files - sending files
   */
    const sendSelectedFiles = async (files) => {
        setFile(files);
        const data = await files.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet);
        setJsonData(json);
      };
      React.useEffect(() => {
        if ((ValidationGroup === '0' && ValidationType === '4') || (ValidationGroup === '8' && ValidationType === '5')) {
          setFormControl(true);
        }
      }, [ValidationGroup, ValidationType]);

      const onSubmitFiles = async () => {
        await dispatch({ type: BULK_UPLOAD_DRUG, payload: file });
        dispatch({ type: GET_MASTER_TEMPLATES });
      };
      useEffect(() => {
        if (status && status?.message !== '') setToaster(true);
        else setToaster(false);
      }, [status]);

  return (
    <Grid container item md={12} spacing={3} sx={{ paddingTop: 2 }} className="dataLayout">
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {/* <Grid item md={BulkUpload === 1 ? 9 : 12} sm={12} xs={12} className="noPadding"> */}
      <Grid item md={12} sm={12} xs={12} className="noPadding">

        <Paper elevation={3} className="splitPages">
          {formControl && (
            <Grid>
              <Typography text="Create District" colorType="primary" type="caption" customClass="userText" />
            </Grid>
          )}
          {formControl && (
            <Form
              entries={FormEnteries}
              isEdit={isFormEdited}
              setIsFormEdited={setIsFormEdited}
              defaultValues={!isFormEdited ? formDefaultValues : editableDefaultValue}
            />
          )}
          {View === 1 && (
            <Table
              onEditClick={(e, ids) => editableDefaultValues(e, ids)}
              isJson={isJson}
              jsonData={jsonData}
              setIsJson={setIsJson}
            />
          )}
        </Paper>
      </Grid>

      {/* {BulkUpload === 1 && (
        <Grid item md={3} sm={12} xs={12} sx={{ height: '100%' }} className="paddingView">
          <Paper elevation={3} className="splitPage">
            <FileUpload
              downloadTemplate
              excelUploader
              templateUrl={getMasterTemplate.drugType}
              masterSetup
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={() => onSubmitFiles()}
              onClickHandleView={onClickHandleView}
            />
            <Grid sx={{ pt: 4 }}>
              <DatePicker calendarPicker date={date} onHandleChange={(newDate) => setDate(newDate)} />
            </Grid>
          </Paper>
        </Grid>
      )} */}
    </Grid>
  )
}

export default District
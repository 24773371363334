/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Grid, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
// import DAFormInput from './DA-Form';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Loader from '../../../../components/atoms/Loader';
import MDAFormInput from '../NewMDA';
import Toaster from '../../../../components/atoms/ToastMessage';
import FileUpload from '../../../../components/molecules/FileUpload';
import Table from '../../../../components/organisms/Table';
import { thData, thDataDis, thDataBlk } from './StaticEntries';
import {
GET_IDA,
GET_MASTER_TEMPLATES,
INSERT_MDA_CHECK,
UPDATE_IDA,
UPLOAD_MDA_IDA,
} from '../../../../constants/actionType';
import './DA.css';
import MDADAFormInput from './DA-Form';
import CustomIcons from '../../../../utils/icons';

/**
*
* @returns {React.ReactElement} - returns the IDA form format
*/
const DAForm = () => {
const dispatch = useDispatch();
const [showTable, setShowTable] = React.useState(false);
const [tablehead, setTableHead] = React.useState(thData);
const [file, setFile] = React.useState();
const [errorVal,setErrorVal] = React.useState([]);
const [openModal, setOpenModal] = React.useState(false);
const { getIDA, loading, status, UploadError } = useSelector((state) => state.IDADetails);
const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
// console.log(getMasterTemplate, 'testttss');
const [toaster, setToaster] = React.useState(false);
const [upload, setUpload] = React.useState(false);
const [isJson, setIsJson] = React.useState(false);
const [jsonData, setJsonData] = React.useState();
const ValidationGroup = localStorage.getItem('userGroupId');
const ValidationType = localStorage.getItem('userTypeId');
const [prompt, setPrompt] = React.useState(false);
const [fieldValue, setFieldValue] = React.useState('');
const [finalDataValue, setFinalDataValue] = React.useState([]);
const InputActionValidation = localStorage.getItem('ActionAccess');
const Actionvalue = JSON.parse(InputActionValidation);
const { BulkUpload } = Actionvalue?.input?.[0];
const userAurhId = localStorage.getItem('UserLoginId');
// console.log(userAurhId, 'userAurhId');

React.useEffect(() => {
  if (status) setToaster(true);
}, [status, ValidationGroup, ValidationType]);
React.useEffect(() => {
  dispatch({ type: INSERT_MDA_CHECK });
}, [dispatch]);
// console.log(finalDataValue, 'sdreertyy');

React.useEffect(() => {
  if (status) setToaster(false);
}, [status]);
React.useEffect(() => {
  if (status) {
    if (status.type === 'INITIAL') {
      setToaster(false);
    } else {
      setToaster(true);
    }
  }
}, [status]);
/**
 *
 */
const onClickHandleView = () => {
  setShowTable(true);
  setIsJson(true);
};

const handleClose = () => setOpenModal(false);

const tableElements = React.useMemo(() => {
  let sitesHead = {};
  return getIDA.flatMap((data) => {
    let sitesRow = {};
    let sitesblock = {};
      if (fieldValue === 'Block Strategy') {
      const blockRows = data?.mdaSurvey?.map((item, index) => {
        if (data.mdaSurvey != null && item.MDA_IDA_id1 !== null && item.MDA_IDA_id2 !== null) {

        const i = index + 1; 
          sitesblock = {
            State: data.state,
            District: data.district,
            Strategy: data.block_strategy,
            ["Program Activity"]: "DA",
            date_planned: item.block_date_planned1,
            date_completed: item.block_date_completed1,
            blockname: item.block_name1,
            covered_population: item.block_covered_population1,
            population: item.block_population1,
            eligible_population: item.block_eligible_population1,
            test_type: item.test_type1,
            nbs_survey_date: item.nbs_survey_date1,
            sentinel_sites_name: item.sentinel_sites_name1,
            sentinel_sites_blood_count: item.sentinel_sites_blood_count1,
            sentinel_sites_possitive: item.sentinel_sites_possitive1,
            site_id_sentinel2: item.sentinel_sites_name2,
            ["BSE R"]: item.sentinel_sites_blood_count2,
            ["Positive R"]: item.sentinel_sites_possitive2,
        };
          return sitesblock;
      }
      return null;
       }).filter(Boolean); 
       setTableHead([...tablehead, ...Object.keys(blockRows[0] || {})]);
        return blockRows;
    }
      if (fieldValue === 'District Strategy') {
      data?.mdaSurvey?.forEach((item, index) => {
        const i = index + 1;

        sitesRow = {
          ...sitesRow,
          [`Block Name${i}`]: item.block_name1,
          [`S${i} Name`]: item.sentinel_sites_name1,
          [`BSE S${i}`]: item.sentinel_sites_blood_count1,
          [`Positive S${i}`]: item.sentinel_sites_possitive1,
          [`Block Name2${i}`]: item.block_name2,
          [`R${i} Name`]: item.sentinel_sites_name2,
          [`BSE R${i}`]: item.sentinel_sites_blood_count2,
          [`Positive R${i}`]: item.sentinel_sites_possitive2,
        };
      });

      setTableHead([...tablehead, ...Object.keys(sitesRow)]);
        return { ...data, ...sitesRow };
    }

    return null;
  });
}, [getIDA]);
React.useEffect(() => {
  dispatch({ type: GET_MASTER_TEMPLATES });
}, [dispatch]);

React.useMemo(() => {
  if (UploadError?.data?.length > 0) {
    setErrorVal(UploadError?.data);
    setOpenModal(true);
  }
},[UploadError]);
/**
 *
 */
const onViewTableData = () => {
  setPrompt(true);
  // dispatch({ type: GET_IDA, payload: 'DA' });
};
// React.useEffect(() => {
//   dispatch({ type: GET_IDA, payload: 'DA' });
// }, [dispatch]);
/**
 *
 * @param {*} item
 * @returns
 */
const BlockDataDetail = (item) => {
  const arr = [];
  // console.log(arr1, 'slkdfjsirr');

  item?.map((val) => {
    const arr1 = [];
    // console.log(item, 'lksjdfsjuir');
    for (let i = 1; i <= val?.Block_Count; i++) {
      arr1.push({
        block_name: val[`Block Name${i}`] || '',
        block_date_completed:
          moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        block_covered_population: 123,
        block_population: 43,
        test_type_id: val[`Test Type${i}`] || 'NBS',
        nbs_survey_date:
          moment(new Date((val[`NBS Survey Date${i}`] - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        site_id1: `S${i}`,
        site_name1: val[`S${i} Name`] || '',
        site_blood_count1: val[`BSE S${i}`] || 0,
        site_possitive1: val[`Positive S${i}`] || 0,
        mf_rate1: 4.3,
        site_id2: `R${i}`,
        site_name2: val[`R${i} Name`] || '',
        site_blood_count2: val[`BSE R${i}`] || 0,
        site_possitive2: val[`Positive R${i}`] || 0,
        mf_rate2: 4.3,
      });
    }

    arr.push({
      program_activity: 'DA',
      state_id: val?.state,
      district_id: val?.district,
      strategy_id: val?.block_strategy,
      date_proposed: moment(new Date((val?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      date_planned: moment(new Date((val?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      date_completed: moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      covered_population: val?.covered_population || '',
      population: val?.population || '',
      block_count: item?.length + 1,
      coverage: arr1,
    });
  });
  return arr;
};
React.useEffect(() => {
  const arr = [];
  jsonData?.map((item) => {
    const arr1 = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= item?.random; i++) {
      // console.log(item[`Block Name2${i}`], 'sdkslkfaksdt');

      arr1.push({
        block_name: item[`Block Name${i}`] !== undefined ? item[`Block Name${i}`] : '',
        site_id: `R${i}`,
        site_name: item[`R${i} Name`] || '',
        site_blood_count: item[`BSE R${i}`],
        site_possitive: item[`Positive R${i}`],
        mf_rate: '77',
      });
    }
    // eslint-disable-next-line no-plusplus
    for (let j = 1; j <= item?.sentinal; j++) {
      arr1.push({
        block_name: item[`Block Name2${j}`] !== undefined ? item[`Block Name2${j}`] : '',
        site_id: `S${j}`,
        site_name: item[`S${j} Name`],
        site_blood_count: item[`BSE S${j}`],
        site_possitive: item[`Positive S${j}`],
        mf_rate: '77',
      });
    }
    return arr.push({
      program_activity: 'DA',
      state_id: item?.state,
      district_id: item?.district,
      strategy_id: item?.block_strategy,
      date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      date_completed:
        moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
      covered_population: item?.covered_population || '',
      population: item?.population || '',
      sentinal: item?.sentinal || '',
      random: item?.random || '',
      test_type_id: item?.test_type,
      nbs_survey_date:
        item?.nbs_survey_date !== undefined
          ? moment(new Date((item?.nbs_survey_date - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD')
          : '',
      coverage: arr1,
    });
  });

  const res = BlockDataDetail(jsonData);
  if (jsonData?.[0]?.block_strategy === 'Block Strategy') {
    setFinalDataValue(res);
  } else {
    setFinalDataValue(arr);
  }
}, [jsonData]);

/**
 * @param {string} key update key
 * @param {Array} EditedItems edited row items
 */

const transformDistrictPayload = (item) => {
  const arr = [];
  item?.map((val) => {
    let sentinalCount = val.sentinal;
    let randamCount = val.random;
    const arr1 = [];
    for (let i = 1; i <= sentinalCount; i++) {
      arr1.push({
         block_name: val[`Block Name${i}`] || '',
         date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        covered_population: val?.covered_population || '',
        population: val?.population || '',
        test_type_id: val[`Test Type${i}`] || 'NBS',
        nbs_survey_date: moment(val.nbs_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        site_id1: `S${i}`,
        site_name1: val[`S${i} Name`] || '',
        // site_name1: val[`S${i} R1 Name`] || '',
        site_blood_count1: val[`BSE S${i}`] || 0,
        site_possitive1: val[`Positive S${i}`] || 0,
        // mf_rate1: 4.3,
        site_name2: val[`R${i} Name`] || '',
      });
    }
    for (let j = 1; j <= randamCount; j++) {
      arr1.push({
         block_name: val[`Block Name2${j}`] || '',
         date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        covered_population: val?.covered_population || '',
        population: val?.population || '',
        eligible_population: val?.eligible_population || '',
        test_type_id: val[`Test Type${j}`] || 'NBS',
        nbs_survey_date: moment(val.nbs_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        site_id1: `R${j}`,
        site_name1: val[`R${j} Name`] || '',
        site_blood_count1: val[`BSE R${j}`] || 0,
        site_possitive1: val[`Positive R${j}`] || 0,
      });
    }
    arr.push({
      program_activity: 'DA',
      state_id: val?.state,
      district_id: val?.district,
      strategy_id: val?.block_strategy,
      date_proposed: moment(val.date_proposed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      date_planned: moment(val.date_planned, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      covered_population: val?.covered_population || '',
      population: val?.population || '',
      eligible_population: val?.eligible_population || '',
      sentinal:val.sentinal,
      random :val.random,
      block_count: item?.length + 1,
      coverage: arr1,
    });
  });
  return arr;
};
const transformBlockPayload = (data) => {
  let blocks=[];
  let districtlist=[];
  for(let i=0;i<data.length;i++){
    let check =districtlist.filter(val=> val.State == data[i].State && val.District == data[i].District);
    if(check.length ==0 ){
      districtlist.push( {
        State:data[i].State,
        District:data[i].District
      });
    }
  }
  blocks = districtlist.map(item=>{
    let mda = (data.filter(d=>d.State == item.State && d.District == item.District));
    const coverageArray = mda.map((entry) => ({
      block_name: entry["Block Name"],
      block_covered_population: entry["Covered Population"],
      block_total_population: entry["Total Population"],
      block_eligible_population: entry["Eligible Population"],
      test_type_id: entry["Test Type"],
      nbs_survey_date: moment(entry["NBS Survey Date"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      date_planned: moment(entry["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      date_completed: moment(entry["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      site_id1: "S1",
      site_name1: entry["Site Name S"],
      site_blood_count1: entry["BSE S"],
      site_possitive1: entry["Positive S"],
      site_id2: "R1",
      site_name2: entry["Site Name R"],
      site_blood_count2: entry["BSE R"],
      site_possitive2: entry["Positive R"],
      district_id:entry.District,
      state_id: entry.State,
    }));
    return {
      program_activity: mda[0]["Program Activity"],
      state_id: item.State,
      district_id: item.District,
      strategy_id: mda[0].Strategy,
      date_planned: moment(mda[0]["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      date_completed: moment(mda[0]["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
      coverage: coverageArray
    };
  })
  return blocks;

};


const handleTableUpdate = async (key, EditedItems) => {

  if (jsonData?.length > 0) {
    if (fieldValue =="District Strategy"){
    dispatch({ type: UPLOAD_MDA_IDA, payload: { data: transformDistrictPayload(jsonData), activity: 'DA' } });
  } else {
    dispatch({ type: UPLOAD_MDA_IDA, payload: { data: transformBlockPayload(jsonData), activity: 'DA' } });
  }
    dispatch({ type: GET_MASTER_TEMPLATES });
  } else {
    dispatch({
      type: UPDATE_IDA,
      payload: { data: transformBlockPayload(EditedItems), activity: 'DA' },
    });
  }

  setTimeout(() => {
    dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'DA', strategy: fieldValue } });
  }, 500);
  setIsJson(false);
};
/**
 *
 */
const handleTableClick = (value) => {
  setFieldValue(value);
  setShowTable(true);

  dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'DA', strategy: value } });
  setShowTable(true);
  setTimeout(() => {
    setPrompt(false);
  }, 1000);
};
// console.log(fieldValue, 'fieldValue');
/**
 *
 */
const handleCancelData = () => {
  setIsJson(false);
  setShowTable(false);
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  // Height: 900,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

if (loading) {
  return <Loader />;
}
/**
 * @param {*} files - sending files
 */
const sendSelectedFiles = async (files) => {
  setFile(files);
  const data = await files.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const json = XLSX.utils.sheet_to_json(worksheet, { raw: false,
    defval: ""
     });
     const correctDateFormat = 'DD-MM-YYYY';
    // const correctDateFormat = 'YYYY-MM-DD';
    const dateErrors = [];

    json.forEach((item) => {
        const state = item.State;
        const district = item.District;
        const errors = [];
        
        Object.keys(item).forEach((key) => {
            if (key.toLowerCase().includes('date') && item[key]) {
                const dateValue = item[key];
                let dateStr = dateValue;
                let [month, day, year] = dateStr.split("/");
                let formattedDate = `${ ("0" +parseInt(day)).slice(-2)}-${("0" + parseInt(month)).slice(-2)}-20${year}`;
                const isValidFormat = moment(formattedDate, correctDateFormat, true).isValid();
                if (!isValidFormat) {
                    errors.push(`${key} is invalid`);
                }
            }
            // if(key.toLowerCase().includes ("bse s")){
            //     if(item[key] > item["Covered Population"] || item[key] > item["covered_population"] ){
            //         errors.push(`${key} should be less than or equal to covered population`)
            //     }
            // }
           });
        if(errors.length>0){
          dateErrors.push({state:state, district:district, msg:errors.toString()});
        }
    });
    if (dateErrors.length > 0) {
        setErrorVal(dateErrors);
        setOpenModal(true);
    }else{
      setJsonData(json);
      setErrorVal([]);
      setOpenModal(false);
    }
};  
/**
 *
 */
const onSubmitFiles = async () => {
  await dispatch({ type: UPLOAD_MDA_IDA, payload: file });
  dispatch({ type: GET_MASTER_TEMPLATES });
};
/**
 *
 * @param {*} data
 * @returns
 */
const handleExcelData = (data) => {
  if (data?.length > 0) {
    return true;
  } else {
    return false;
  }
};
// console.log(jsonData, 'sdfksfkjasrr');
return (
  <Grid container item md={12} spacing={3} className='DaFormat'>
    {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
    {prompt && (
      <Dialog
        open={true}
        // onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions>
          <Button onClick={() => handleTableClick('District Strategy')}>District Strategy</Button>
          <Button onClick={() => handleTableClick('Block Strategy')}>Block Strategy</Button>
        </DialogActions>
      </Dialog>
    )}
    
<Modal
open={openModal}
  // onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box sx={style} style={{ height: "25rem", overflowY: "scroll"}}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1px' }}>
      <h2 id="modal-modal-title">Error Log </h2>
      <button onClick={handleClose} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
        &times; {/* This is a simple close icon */}
      </button>
    </div>
    <table className="modal-table">
      <thead>
        <tr>
          <th>S.No</th>
          <th>State</th>
          <th>District</th>
          <th>Error Message</th>
        </tr>
      </thead>
      <tbody>
        {errorVal.map(({ state, district, msg }, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{state}</td>
            <td>{district}</td>
            <td>{msg}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </Box>
</Modal>
    <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
      <Paper elevation={3} className="splitPage">
        {showTable ? (
          <Table
            header={fieldValue === 'Block Strategy' ? thDataBlk : thDataDis}
            rows={isJson ? jsonData : tableElements}
            inputTable
            iconClick="false"
            hideKeyFields={[
              'MDA_IDA_id',
              'state_id',
              'district_id',
              'block_strategy_id',
              'random',
              'sentinal',
              'mdaSurvey',
              'nbs_date_completed',
              'Block_Count',
            ]}
            showSno
            optional={false}
            onSendUpdatedData={handleTableUpdate}
            onCancel={() => handleCancelData()}
            Activity="DA"
            uniqueKey="MDA_IDA_id"
            ExcelData={handleExcelData(jsonData)}
            IsExcelData={isJson}
            screen="input"
          />
        ) : (
          // <DAFormInput OnView={onViewTableData} notify={(show) => setToaster(show)} />
          <MDAFormInput OnView={onViewTableData} Activity="DA" />
        )}
      </Paper>
    </Grid>

    {upload === false && (
      <Tooltip title="File Upload " placement="top">
        <Grid
          onClick={() => BulkUpload === 1 && setUpload(true)}
          padding="10px"
          marginTop="40px"
          className="uploadContainer"
        >
          {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
          {/* <p className="uploadFile">File Upload</p> */}
          <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
        </Grid>
      </Tooltip>
    )}

    {upload === true && (
      <Grid item md={3} sm={12} xs={12} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          <Grid>
            <FileUpload
              templateUrl={fieldValue === 'Block Strategy' ? getMasterTemplate.daBlock : getMasterTemplate.daDistrict}
              downloadTemplate
              excelUploader
              sendSelectedFile={(files) => sendSelectedFiles(files)}
              onSubmitFiles={onSubmitFiles}
              handleCancel={() => setUpload(false)}
              onClickHandleView={onClickHandleView}
            />
          </Grid>
        </Paper>
      </Grid>
    ) }
  </Grid>
);
};

export default DAForm;

/* eslint-disable no-plusplus */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable array-callback-return */
/* eslint-disable require-jsdoc */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Grid, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useDispatch, useSelector } from 'react-redux';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Tooltip from '@material-ui/core/Tooltip';
import IDAFormInput from './IDA-Form';
import MDAFormInput from '../NewMDA';
import Loader from '../../../../components/atoms/Loader';
import Toaster from '../../../../components/atoms/ToastMessage';
import FileUpload from '../../../../components/molecules/FileUpload';
import Table from '../../../../components/organisms/Table';
import { thData, thDataDis } from './StaticEntries';
import {DATEFORMAT,ERROR} from '../../../../constants/common/index';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  GET_IDA,
  GET_MASTER_TEMPLATES,
  INSERT_MDA_CHECK,
  INSERT_TAS_CHECK,
  UPDATE_IDA,
  UPLOAD_MDA_IDA,
  IDA_STATUS
} from '../../../../constants/actionType';
import './IDA.css';
import CustomIcons from '../../../../utils/icons';
import { Height } from '@material-ui/icons';
/**
 *
 * @returns {React.ReactElement} - returns the IDA form format
 */
const IDAForm = (props) => {
  const { onsubmit } = props;
  const dispatch = useDispatch();
  const [showTable, setShowTable] = React.useState(false);
  const [file, setFile] = React.useState();
  const [tablehead, setTableHead] = React.useState(thData);
  const { getIDA, loading, status, UploadError } = useSelector((state) => state.IDADetails);
  const { getMasterTemplate } = useSelector((state) => state.masterTemplate);
  // console.log(getIDA, 'fissdddle');
  const [toaster, setToaster] = React.useState(false);
  const [constVal, setConstVal] = React.useState(0);
  const [upload, setUpload] = React.useState(false);
  const [ExcelData, setExcelData] = React.useState();
  const [jsonData, setJsonData] = React.useState();
  const [isJson, setIsJson] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);
  const [fieldValue, setFieldValue] = React.useState('District Strategy');
  const [finalDataValue, setFinalDataValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const InputActionValidation = localStorage.getItem('ActionAccess');
  const Actionvalue = JSON.parse(InputActionValidation);
  const { BulkUpload } = Actionvalue?.input?.[0];
  React.useEffect(() => {
    dispatch({ type: INSERT_MDA_CHECK });
  }, [dispatch]);
  // console.log(moment(new Date('02/02/2022')).format('YYYY-MM-DD'), 'finalDataValue');
  React.useEffect(() => {
    if (status) setToaster(false);
  }, [status]);

  React.useEffect(() => {
    if (status) {
      if (status.type === 'INITIAL') {
        setToaster(false);
      } else {
        setToaster(true);
      }
    }
  }, [status]);
  const handleClose = () => setOpenModal(false);
  const tableElements = React.useMemo(() => {
    let sitesHead = {};
    return getIDA.flatMap((data) => {
      let sitesRow = {};
      let sitesblock = {};
    
      if (fieldValue === 'Block Strategy') {
        const blockRows = data?.mdaSurvey?.map((item, index) => {
          if (data.mdaSurvey != null && item.MDA_IDA_id1 !== null && item.MDA_IDA_id2 !== null) {
            const i = index + 1;
            sitesblock = {
              State: data.state,
              District: data.district,
              Strategy: data.block_strategy,
              ["Program Activity"]: "IDA",
              date_planned: item.block_date_planned1,
              date_completed: item.block_date_completed1,
              blockname: item.block_name1,
              covered_population: item.block_covered_population1,
              population: item.block_population1,
              eligible_population: item.block_eligible_population1,
              test_type: item.test_type1,
              nbs_survey_date: item.nbs_survey_date1,
              sentinel_sites_name: item.sentinel_sites_name1,
              sentinel_sites_blood_count: item.sentinel_sites_blood_count1,
              sentinel_sites_possitive: item.sentinel_sites_possitive1,
              site_id_sentinel2: item.sentinel_sites_name2,
              ["BSE R"]: item.sentinel_sites_blood_count2,
              ["Positive R"]: item.sentinel_sites_possitive2,
            };
            return sitesblock;
          }
          return null;
        }).filter(Boolean); 
          setTableHead([...tablehead, ...Object.keys(blockRows[0] || {})]);
        return blockRows;
      }
          if (fieldValue === 'District Strategy') {
        data?.mdaSurvey?.forEach((item, index) => {
            if (item.MDA_IDA_id1 !== null) {
            const i = index + 1;
    
            sitesRow = {
              ...sitesRow,
              [`Block Name${i}`]: item.block_name1,
              [`S${i} Name`]: item.sentinel_sites_name1,
              [`BSE S${i}`]: item.sentinel_sites_blood_count1,
              [`Positive S${i}`]: item.sentinel_sites_possitive1,
              [`Block Name2${i}`]: item.block_name2,
              [`R${i} Name`]: item.sentinel_sites_name2,
              [`BSE R${i}`]: item.sentinel_sites_blood_count2,
              [`Positive R${i}`]: item.sentinel_sites_possitive2,
            };
          }
        });
            setTableHead([...tablehead, ...Object.keys(sitesRow)]);
        return { ...data, ...sitesRow };
      }
    
      return null;
    });
    
  }, [getIDA]);
  
  
  const BlockDataDetail = (item) => {
    const arr = [];
    item?.map((val) => {
      const arr1 = [];
      for (let i = 1; i <= val?.Block_Count; i++) {
        arr1.push({
           block_name: val[`Block Name${i}`] || '',
          block_date_completed:
            moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
          block_covered_population: 123,
          block_population: 43,
          test_type_id: val[`Test Type${i}`] || 'NBS',
          nbs_survey_date:
            moment(new Date((val[`NBS Survey Date${i}`] - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
          site_id1: `S${i}`,
          site_name1: val[`S${i} Name`] || '',
          site_name1: val[`S${i} Site Name S`] || '',
          site_blood_count1: val[`BSE S${i}`] || 0,
          site_possitive1: val[`Positive S${i}`] || 0,
          mf_rate1: 4.3,
          site_id2: `R${i}`,
          site_name2: val[`R${i} S1 Name`] || '',
          site_blood_count2: val[`BSE R${i}`] || 0,
          site_possitive2: val[`Positive R${i}`] || 0,
          mf_rate2: 4.3,
        });
      }

      arr.push({
        program_activity: 'IDA',
        state_id: val?.state,
        district_id: val?.district,
        strategy_id: val?.block_strategy,
        date_planned: moment(new Date((val?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_completed: moment(new Date((val?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        covered_population: val?.covered_population || '',
        population: val?.population || '',
        block_count: item?.length + 1,
        coverage: arr1,
      });
    });
    return arr;
  };
  // console.log(jsonData?.[0]?.block_strategy === 'Block Strategy', 'tableElements');
  React.useEffect(() => {
    const arr = [];
    jsonData?.map((item) => {
      const arr1 = [];
      // console.log(item, 'sdjjshrut');
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= item?.random; i++) {
        // console.log(item[`Block Name2${i}`], 'sdkslkfaksdt');

        arr1.push({
          block_name: item[`Block Name${i}`] !== undefined ? item[`Block Name${i}`] : '',
          site_id: `R${i}`,
          site_name: item[`R${i} Name`] || '',
          site_blood_count: item[`BSE R${i}`],
          site_possitive: item[`Positiv e R${i}`],
          mf_rate: '77',
        });
      }
      // eslint-disable-next-line no-plusplus
      for (let j = 1; j <= item?.sentinal; j++) {
        arr1.push({
          block_name: item[`Block Name2${j}`] !== undefined ? item[`Block Name2${j}`] : '',
          site_id: `S${j}`,
          site_name: item[`S${j} Name`],
          site_blood_count: item[`BSE S${j}`],
          site_possitive: item[`Positive S${j}`],
          mf_rate: '77',
        });
      }
      // moment(new Date(value.toString()?.split('/')?.reverse()?.join('-'))).format('YYYY-MM-DD');
      return arr.push({
        program_activity: 'IDA',
        state_id: item?.State,
        district_id: item?.District,
        strategy_id: item?.["Block Strategy"],
        // date_proposed: moment(new Date((item?.date_proposed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_planned: moment(new Date((item?.date_planned - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
        date_completed:
          moment(new Date((item?.date_completed - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '',
            covered_population: item?.["Covered Population"] || '',
        population: item?.["Total Population"] || '',
        // sentinal: item?.sentinal || '',
        // random: item?.random || '',
        // test_type_id: item?.test_type,
        // nbs_survey_date:
        //   item?.nbs_survey_date !== undefined
        //     ? moment(new Date((item?.nbs_survey_date - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD')
        //     : '',
        coverage: arr1,
      });
    });

    const res = BlockDataDetail(jsonData);
    if (jsonData?.[0]?.["Strategy"] === 'Block Strategy') {
      setFinalDataValue(res);
    } else {

      setFinalDataValue(arr);
    }
  }, [jsonData]);

  /**
   *
   */
  const handleTableClick = (value) => {
    setFieldValue(value);
    dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'IDA', strategy: value } });
    setShowTable(true);
    setTimeout(() => {
      setPrompt(false);
    }, 1000);
  };
  /**
   *
   */
  const onViewTableData = () => {
    setPrompt(true);
    // dispatch({ type: GET_IDA, payload: 'IDA' });
    // setShowTable(true);
  };
  React.useEffect(() => {
    dispatch({ type: GET_MASTER_TEMPLATES });
  }, [dispatch]);
  /**
   * @param {string} key update key
   * @param {Array} EditedItems edited row items
   */
  const transformDistrictPayload = (item) => {
    const arr = [];
    item?.map((val) => {
      let sentinalCount = val.sentinal;
      let randamCount = val.random;
      const arr1 = [];
      for (let i = 1; i <= sentinalCount; i++) {
        arr1.push({
           block_name: val[`Block Name${i}`] || '',
           date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          covered_population: val?.covered_population || '',
          population: val?.population || '',
          test_type_id: val[`Test Type${i}`] || 'NBS',
          nbs_survey_date: moment(val.nbs_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          site_id1: `S${i}`,
          site_name1: val[`S${i} Name`] || '',
          // site_name1: val[`S${i} R1 Name`] || '',
          site_blood_count1: val[`BSE S${i}`] || 0,
          site_possitive1: val[`Positive S${i}`] || 0,
          // mf_rate1: 4.3,
          site_name2: val[`R${i} Name`] || '',
        });
      }
      for (let j = 1; j <= randamCount; j++) {
        arr1.push({
           block_name: val[`Block Name2${j}`] || '',
           date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          covered_population: val?.covered_population || '',
          population: val?.population || '',
          eligible_population: val?.eligible_population || '',
          test_type_id: val[`Test Type${j}`] || 'NBS',
          nbs_survey_date: moment(val.nbs_survey_date, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          site_id1: `R${j}`,
          site_name1: val[`R${j} Name`] || '',
          site_blood_count1: val[`BSE R${j}`] || 0,
          site_possitive1: val[`Positive R${j}`] || 0,
        });
      }
      arr.push({
        program_activity: 'IDA',
        state_id: val?.state,
        district_id: val?.district,
        strategy_id: val?.block_strategy,
        date_proposed: moment(val.date_proposed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        date_planned: moment(val.date_planned, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        date_completed: moment(val.date_completed, 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
        covered_population: val?.covered_population || '',
        population: val?.population || '',
        eligible_population: val?.eligible_population || '',
        sentinal:val.sentinal,
        random :val.random,
        block_count: item?.length + 1,
        coverage: arr1,
      });
    });
    return arr;
  };

  const transformBlockPayload = (data) => {
      let blocks=[];
      let districtlist=[];
      for(let i=0;i<data.length;i++){
        let check =districtlist.filter(val=> val.State == data[i].State && val.District == data[i].District);
        if(check.length ==0 ){
          districtlist.push( {
            State:data[i].State,
            District:data[i].District
          });
        }
      }
      blocks = districtlist.map(item=>{
        let mda = (data.filter(d=>d.State == item.State && d.District == item.District));
        const coverageArray = mda.map((entry) => ({
          block_name: entry["Block Name"],
          block_covered_population: entry["Covered Population"],
          block_total_population: entry["Total Population"],
          block_eligible_population: entry["Eligible Population"],
          test_type_id: entry["Test Type"],
          nbs_survey_date: moment(entry["NBS Survey Date"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          date_planned: moment(entry["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          date_completed: moment(entry["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          site_id1: "S1",
          site_name1: entry["Site Name S"],
          site_blood_count1: entry["BSE S"],
          site_possitive1: entry["Positive S"],
          site_id2: "R1",
          site_name2: entry["Site Name R"],
          site_blood_count2: entry["BSE R"],
          site_possitive2: entry["Positive R"],
          district_id:entry.District,
          state_id: entry.State,
        }));
        return {
          program_activity: mda[0]["Program Activity"],
          state_id: item.State,
          district_id: item.District,
          strategy_id: mda[0].Strategy,
          date_planned: moment(mda[0]["Date Planned"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          date_completed: moment(mda[0]["Date Completed"], 'MM/DD/YYYY').format('YYYY-MM-DD') || '',
          coverage: coverageArray
        };
      })
      return blocks;
    };
    
  

  const handleTableUpdate = async (key, EditedItems) => {
    debugger;
    if (jsonData?.length > 0) {
     if (fieldValue =="District Strategy"){
      dispatch({ type: UPLOAD_MDA_IDA, payload: { data: transformDistrictPayload(jsonData), activity: 'IDA' } });
    } else {
        dispatch({ type: UPLOAD_MDA_IDA, payload: { data: transformBlockPayload(jsonData), activity: 'IDA' } });
        }
       dispatch({ type: GET_MASTER_TEMPLATES });
    } else {
      dispatch({
        type: UPDATE_IDA,
        payload: { data: transformBlockPayload(EditedItems), activity: 'IDA' },
      });
    }
    setTimeout(() => {
      dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'IDA', strategy: fieldValue } });
    }, 500);
    setIsJson(false);
  };
  /**
   *
   */
  const HandleUpload = () => {
    if (BulkUpload === 1) setUpload(true);
  };
  /**
   *
   */
  const onClickHandleView = () => {
    setShowTable(true);
    setIsJson(true);
    // handleOpen(true);
  };
  const completeDateVal = jsonData?.map((data) => 
    data?.date_planned
  ) || [];
  const [dateDestructure] = completeDateVal;
  const dateConvert = moment(new Date((dateDestructure - (25567 + 2)) * 86400 * 1000)).format('YYYY-MM-DD') || '';
  
  // console.log("jsonVal",dateConvert); // 1970-02-12
  const [errorVal,setErrorVal] = React.useState([]);
  const [stateVal,setStateVal] = React.useState([]);
  const [stateValue,districtValue] = stateVal;
  // const data = [
  //   { state: stateValue, district: districtValue, errorMessage: errorVal },
  //   // { state: 'Punjab', district: 'Ferozpur', errorMessage: 'date planned are invalid' },
  //   // Add more entries as needed
  // ];
  // console.log("dateErrors",errorVal);
  // console.log("dateErrors",stateVal);

React.useMemo(() => {
  if (UploadError?.data?.length > 0) {
    setErrorVal(UploadError?.data);
    setOpenModal(true);
  }
},[UploadError]);


  // useEffect(() => {
  //   dispatch({ type: GET_IDA, payload: { proposed_program_activities: 'IDA', strategy: 'District Strategy' } });
  // }, [isJson, fieldValue]);
  if (loading) {
    return <Loader />;
  }

  /**
   * @param {*} files - sending files
   */
  const sendSelectedFiles = async (files) => {
    setFile(files);
    const data = await files.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const json = XLSX.utils.sheet_to_json(worksheet, { raw: false,
    defval: ""
     });
     const correctDateFormat = 'DD-MM-YYYY';
    // const correctDateFormat = 'YYYY-MM-DD';
    const dateErrors = [];

    json.forEach((item) => {      
        const state = item.State;
        const district = item.District;
        const errors = [];
        
        Object.keys(item).forEach((key) => {
            if (key.toLowerCase().includes('date') && item[key]) {
                const dateValue = item[key];
                let dateStr = dateValue;
                let [month, day, year] = dateStr.split("/");
                let formattedDate = `${ ("0" +parseInt(day)).slice(-2)}-${("0" + parseInt(month)).slice(-2)}-20${year}`;
                const isValidFormat = moment(formattedDate, correctDateFormat, true).isValid();
                if (!isValidFormat) {
                    errors.push(`${key} is invalid`);
                }
            }
          //   if(key.toLowerCase().includes ("bse s")){
          //     if(item[key] > item["Covered Population"] || item[key] > item["covered_population"] ){
          //         errors.push(`${key} should be less than or equal to covered population`)
          //     }
          // }
        });
        if(errors.length>0){
          dateErrors.push({state:state, district:district, msg:errors.toString()});
        }
    });
    if (dateErrors.length > 0) {
        setErrorVal(dateErrors);
        setOpenModal(true);
    }else{
      setJsonData(json);
      setErrorVal([]);
      setOpenModal(false);
    }
  };
 
  // const sendSelectedFiles = async (files) => {
  //   debugger;
  //   setFile(files); 
  //   const data = await files.arrayBuffer(); 
  //   const workbook = XLSX.read(data); 
  //   const worksheet = workbook.Sheets[workbook.SheetNames[0]]; 
  //   const json = XLSX.utils.sheet_to_json(worksheet); 
  //   const uniqueCount = {};
  //   const processedRows = [];
  //   json.forEach((row) => {
  //     const uniqueKey = `${row.State}-${row.District}-${row.Strategy}`;
  //     if (!uniqueCount[uniqueKey]) {
  //       uniqueCount[uniqueKey] = 1; 
  //       processedRows.push(row); 
  //     } else {
  //       uniqueCount[uniqueKey] += 1; 
  //       const newRow = {
  //         ...row,
  //         State: '', 
  //         District: '',
  //         Strategy: '',
  //         'Program Activity': '', 
  //         'Date Planned': '', 
  //         'Date Completed': '',
  //       };
  //       processedRows.push(newRow);
  //     }
  //   });
  //   setJsonData(processedRows); 
  // };
  
  
  
  const handleCanceldata = () => {
    setShowTable(false);
    setFinalDataValue([]);
    setJsonData();
    setIsJson(false);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // Height: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  /**
   *
   */
  
  const onSubmitFiles = async () => {
    await dispatch({ type: UPLOAD_MDA_IDA, payload: jsonData });
    dispatch({ type: GET_MASTER_TEMPLATES });
  };
  return (
    <Grid container item md={12} spacing={3} className='IdaFormat'>
      {toaster && <Toaster open severity={status?.type} message={status?.message} close={() => setToaster(false)} />}
      {prompt && (
        <Dialog
          open={true}
          // onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogActions>
            <Button onClick={() => handleTableClick('District Strategy')}>District Strategy</Button>
            <Button onClick={() => handleTableClick('Block Strategy')}>Block Strategy</Button>
          </DialogActions>
        </Dialog>
      )}

<Modal
open={openModal}
  // onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box sx={style} style={{ height: "25rem", overflowY: "scroll"}}>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1px' }}>
      <h2 id="modal-modal-title">Error Log </h2>
      <button onClick={handleClose} style={{ border: 'none', background: 'none', cursor: 'pointer' }}>
        &times; {/* This is a simple close icon */}
      </button>
    </div>
    <table className="modal-table">
      <thead>
        <tr>
          <th>S.No</th>
          <th>State</th>
          <th>District</th>
          <th>Error Message</th>
        </tr>
      </thead>
      <tbody>
        {errorVal.map(({ state, district, msg }, index) => (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>{state}</td>
            <td>{district}</td>
            <td>{msg}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </Box>
</Modal>

      <Grid item md={upload === true ? 9 : 11.4} className="layoutFormat">
        <Paper elevation={3} className="splitPage">
          {showTable ? (
            <Table
              header={fieldValue === 'Block Strategy' ? thData : thDataDis}
              rows={isJson ? jsonData : tableElements}
              inputTable
              iconClick="false"
              hideKeyFields={[
                'MDA_IDA_id',
                'state_id',
                'district_id',
                'block_strategy_id',
                'random',
                'sentinal',
                'mdaSurvey',
                'nbs_date_completed',
              ]}
              showSno
              optional={false}
              onSendUpdatedData={handleTableUpdate}
              onCancel={() => handleCanceldata()}
              Activity="IDA"
              uniqueKey="MDA_IDA_id"
              IsExcelData={isJson}
              screen="input"
            />
          ) : (
            <MDAFormInput OnView={onViewTableData} Activity="IDA" />
          )}
        </Paper>
      </Grid>
      {upload === false && (
        <Tooltip title="File Upload " placement="top">
          <Grid onClick={HandleUpload} padding="10px" marginTop="40px" className="uploadContainer">
            {/* <img src={CustomIcons.LeftArrow} alt="upload" className="uploadImgFile" /> */}
            {/* <p className="uploadFile">File Upload</p> */}
            <KeyboardDoubleArrowLeftIcon fontSize="large" color="primary" className="uploadImgFile" />
          </Grid>
        </Tooltip>
      )}
      {upload === true && (
        <Grid item md={3} sm={12} xs={12} className="layoutFormat">
          <Paper elevation={3} className="splitPage">
            <Grid>
              <FileUpload
                templateUrl={
                  fieldValue === 'Block Strategy' ? getMasterTemplate.idaBlock : getMasterTemplate.idaDistrict
                }
                downloadTemplate
                excelUploader
                rows={isJson ? jsonData : tableElements}
                sendSelectedFile={(files) => sendSelectedFiles(files)}
                onSubmitFiles={onSubmitFiles}
                handleCancel={() => setUpload(false)}
                onClickHandleView={onClickHandleView}
                Activity="IDA"
              />
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default IDAForm;

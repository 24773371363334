/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';
import dashboardReducer from './dashboardReducer';
import programActivityReducer from './programActivityReducer';
import nationalDashboardReducer from './nationalDashboardReducer';
import stateDashboardReducer from './stateDashboardReducer';
import insertDrugReducer from './insertDrugReducer';
import drugTypeReducer from './drugTypeReducer';
import newUserReducer from './newUserReducer';
import loginReducer from './loginReducer';
import userRoleReducer from './userRole.reducer';
import IDAReducer from './IDAReducer';
import programStatusReducer from './programStatusReducer';
import geographicReducer from './geographicDimensionReducer';
import DemographicDimensionsReducer from './demographicsDimensionsReducer';
import dropDownReducer from './dropdownReducer';
import dossierReducer from './dossierReducer';
import lymphedemaReducer from './lymphedemaReducer';
import preTasReducer from './PreTasReducer';
import TASReducer from './TASReducer';
import thresholdReducer from './thresholdConfigReducer';
import formulaReducer from './formulaConfigReducer';
import masterTemplateReducer from './masterTemplateReducer';
import predictionDashoboardReducer from './predictionDashboardReducer';
import CustomReportReducer from './customReportReducer';
import dropDownSearchReducer from './dropdownSearchReducer';
import districtReducer from './districtReducer';
import stateReducer from './stateReducer';

/**
 *
 */
const reducer = combineReducers({
  dashboardDetail: dashboardReducer,
  programActivity: programActivityReducer,
  nationalDashboardDetails: nationalDashboardReducer,
  stateDashboardDetails: stateDashboardReducer,
  insertDrugDetails: insertDrugReducer,
  drugTypeDetails: drugTypeReducer,
  newUserDetails: newUserReducer,
  loginDetails: loginReducer,
  userRole: userRoleReducer,
  IDADetails: IDAReducer,
  programStatus: programStatusReducer,
  geographic: geographicReducer,
  demographic: DemographicDimensionsReducer,
  dropDownReducer,
  dossier: dossierReducer,
  lymphedema: lymphedemaReducer,
  preTas: preTasReducer,
  TASDetails: TASReducer,
  thresholdConfig: thresholdReducer,
  formulaConfig: formulaReducer,
  masterTemplate: masterTemplateReducer,
  predictionDashoboard: predictionDashoboardReducer,
  CustomReportReducer,
  dropDownSearch: dropDownSearchReducer,
  districtDeatils:districtReducer,
  stateDetails:stateReducer
});

export { reducer };

export const lymphedemaFormEntries = [
  {
    count: true,
    entryName: 'state',
    placeholder: 'State',
    dropdown: true,
    data: ['Kerala', 'TamilNadu', 'Karnataka'],
    validation: { isRequired: true },
  },
  {
    count: true,
    entryName: 'district',
    placeholder: 'District',
    dropdown: true,
    disabled: true,
    data: ['Chennai', 'Salem', 'Thiruvallur'],
    validation: { isRequired: true },
  },
  {
    default: true,
    textField: true,
    entryName: 'lymphedema_cases',
    placeholder: 'Lymphedema Cases',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    default: true,
    textField: true,
    entryName: 'hydrocele_case',
    placeholder: 'Hydrocele Cases',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    default: true,
    textField: true,
    entryName: 'hydrocele_surgeries',
    placeholder: 'Hydrocele Surgeries',
    textInputType: 'number',
    validation: { isRequired: false },
  },
  {
    default: true,
    textField: true,
    entryName: 'year',
    placeholder: 'Year',
    textInputType: 'number',
    validation: { isRequired: true },
  },
  {
    default: true,
    textField: true,
    entryName: 'mmdp_kits_distributed',
    placeholder: 'MMDP Kits distributed',
    // textInputType: 'number',
    validation: { isRequired: false },
  },
];
export const formDefaultValues = {
  state: '',
  district: '',
  lymphedema_cases: '',
  hydrocele_case: '',
  hydrocele_surgeries: '',
  year:'',
  mmdp_Kits_distributed:'',
  
};

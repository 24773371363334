/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable require-jsdoc */
/* eslint-disable no-inner-declarations */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomIcons from '../../../utils/icons';
import Typography from '../../atoms/Typography';
import colors from '../../../utils/colors';
import './progressChart.css';
/**
 * chart Component
 *
 * @param {*} props all props
 * @param {Array[]} props.data used for chart data
 * @param {Array[]} props.title used for chart title
 * @param {string} props.XLabel used for chart XLabel
 * @param {string} props.YLabel used for chart YLabel
 * @param {Array[]} props.getProgressLegends used for chart YLabel
 * @returns {React.ReactElement} - return chart
 */
const BarChart = ({ data, type, getProgressLegends, strategyType }) => {
  const { activityLegends } = useSelector((state) => state.dashboardDetail);
  const [activityLegendsColor, setActivityLegendsColor] = useState({});

  useEffect(() => {
    if (activityLegends?.mapLegends && getProgressLegends && getProgressLegends.length > 0) {
      const legends = JSON.parse(JSON.stringify(activityLegends?.mapLegends));

      const keys = Object.keys(legends);
      const finalLegends = {};
      keys.map((keyData) => {
        if (
          getProgressLegends.indexOf(keyData) > -1 ||
          (type === 'national' && keyData === 'Total Endemic Districts') ||
          (type === 'state' && strategyType === 'Block Strategy' && keyData === 'Total Endemic Blocks') ||
          (type === 'state' && strategyType === 'District Strategy' && keyData === 'Total Endemic Districts')
        ) {
          finalLegends[keyData] = legends[keyData];
        }
        return true;
      });
      setActivityLegendsColor(finalLegends);
    } else {
      setActivityLegendsColor({});
    }
  }, [activityLegends, getProgressLegends]);
  // /**
  //  *
  //  */
  // function move() {
  //   let i = 0;
  //   if (i === 0) {
  //     i = 1;
  //     const elem = document.getElementById('myBar');
  //     let width = 10;
  //     const id = setInterval(frame, 10);
  //     /**
  //      *
  //      */
  //     function frame() {
  //       if (width >= 100) {
  //         clearInterval(id);
  //         i = 0;
  //       } else {
  //         width++;
  //         elem.style.width = `${width}%`;
  //         elem.innerHTML = `${width}%`;
  //       }
  //     }
  //   }
  // }

  const getWidth = (total, current) => {
    const width = (current / total) * 100;
    return `${width.toFixed(2)}%`;
  };

  return (
    <div id="parentProgressDiv">
      <div className="progressBarLegendsSub">
        {(type === 'national' || type === 'state') && (
          <label className="DisclaimerText">District going No activity Done are part of Grey scale.</label>
        )}
        <div className="progressBarLegends">
          {Object.entries(activityLegendsColor)?.map(([key, value]) => {
            return (
              <div className="lengedContent">
                <div style={{ borderRadius: '0px', backgroundColor: value, width: 17, height: 10 }} />
                <Typography text={key} type="body2" colorType="text" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="subMainData">
        {data?.length > 0 ? (
          data?.map((progressData) => {
            return (
              <div className="subMain">
                <div className="stateNameDiv">{progressData.state || progressData.district || ''}</div>
                <div className="progressDiv">
                  <div className="myProgress">
                    {progressData.data.map((childData) => {
                      return (
                        <div
                          className={childData.name}
                          style={{
                            width: getWidth(progressData.totalEndemicDistricts, childData.value),
                            background: activityLegendsColor?.[childData.name] || colors.typeCode.default,
                          }}
                        >
                          {childData.value}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="districtCount">{progressData.totalEndemicDistricts}</div>
              </div>
            );
          })
        ) : (
          <center>
            <div className="nodata-img">
              <img style={{ width: '100%' }} src={CustomIcons.NoDataFound} alt="No Data Found" />
            </div>
          </center>
        )}
      </div>
    </div>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf().isRequired,
  type: PropTypes.string.isRequired,
  getProgressLegends: PropTypes.arrayOf().isRequired,
  strategyType: PropTypes.objectOf().isRequired,
};
export default BarChart;

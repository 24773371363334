import React from 'react';
import { districtState } from '../initialStates';
import { GET_DISTRICT, GET_DISTRICT_RESULT } from '../../constants/actionType';

const districtReducer = (state = districtState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DISTRICT:
      return {
        ...state,
        payload,
      };
    case GET_DISTRICT_RESULT:
      return {
        ...state,
        getDistrict: payload.data,
      };

    default:
      return state;
  }
};

export default districtReducer;

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CustomButton from '../../atoms/CustomButton';
import './FileUpload.css';
import ProgressBar from '../../atoms/ProgressBar';
import Typography from '../../atoms/Typography';
import TextField from '../../atoms/TextField';
import CustomIcons from '../../../utils/icons';
import colors from '../../../utils/colors';
/**
 * @name FileUpload
 * @param {object} props required props
 * @returns {React.ReactElement} returns the fileUpload Component
 */
const FileUpload = (props) => {
  const [showFileName, setShowFileName] = useState('');
  const [notes, setNotes] = useState('');
  const [attachFile, setAttachFile] = useState({ name: '', file: '' });
  const [showApprovalFile, setShowApprovalFile] = useState('');
  const [showProgressUpload, setShowProgressUpload] = useState(true);
  const [showProgressApprove, setShowProgressApprove] = useState(true);
  const [progressUpload, setProgressUpload] = useState(0);
  const [progressApprove, setProgressApprove] = useState(0);
  const {
    downloadTemplate,
    wordUploader,
    excelUploader,
    bulkUploader,
    masterSetup,
    onSubmitFiles,
    sendSelectedFile,
    sendAttachment,
    templateUrl,
    handleCancel,
    onClickHandleView,
    isSubmit,
  } = props;
  /**
   * @param {*} e - event of onchange
   */
  const onUploadFile = (e) => {
     const selectedFile = e.target.files[0];
    
    sendSelectedFile(selectedFile);
    setShowFileName(selectedFile.name);
    setShowProgressUpload(true);
    setProgressUpload(100);
  };
  /**
   *
   * @param {*} e - event of onchange
   * @param {string} key - passing key value
   */
  const attachmentFiles = (e, key) => {
    const approvalNotes = e.target.value;
    if (key !== 'upload') {
      setNotes(approvalNotes);
      attachFile.name = approvalNotes;
      sendAttachment({ name: approvalNotes, file: attachFile.file });
    } else {
      const attachmentFile = e.target.files[0];
      attachFile.file = attachmentFile;
      sendAttachment({ name: attachFile.name, file: attachmentFile });
      setShowApprovalFile(attachmentFile.name);
      setProgressApprove(100);
    }

    setAttachFile((prevState) => ({
      ...prevState,
    }));
    setShowProgressApprove(true);
  };

  /**
   * @function onCancel oncancel
   */
  const onCancel = () => {
    setAttachFile({ name: '', file: '' });
    setShowApprovalFile('');
    setShowProgressUpload(true);
    setShowProgressApprove(true);
    setProgressUpload(0);
    setProgressApprove(0);
    setNotes('');
    setShowFileName('');
    handleCancel();
  };

  /**
   *
   */
  const onClickHandleSubmit = () => {
    onSubmitFiles();
    setNotes('');
    onCancel();
  };
  /**
   *
   */
  const onCloseProgress = () => {
    setShowProgressUpload(false);
    setShowProgressApprove(false);
  };

  return (
    <Grid>
      <Typography text="Bulk Upload" type="caption" customClass="bulkUpload" />

      {bulkUploader && (
        <Grid className="bulkUpload" sx={{ p: 2 }}>
          <Grid className="textFieldStyle">
            <TextField label="Approval Note" multiline onChange={(e) => attachmentFiles(e)} value={notes} />
          </Grid>
        </Grid>
      )}

      <Grid className="btnGrid">
        {wordUploader && (
          <Grid>
            <Grid className="uploadBtnGrid">
              <Grid className="approvalBtn">
                <label htmlFor="upload-photo" className="uploadLabel" style={{ color: colors.blue.nonary }}>
                  Approval Proof Attachments
                </label>
                <img src={CustomIcons.WordUpload} alt="upload" className="uploadImg" />
              </Grid>
              <input
                type="file"
                name="photo"
                id="upload-photo"
                className="input"
                onChange={(e) => attachmentFiles(e, 'upload')}
              />
            </Grid>
            {showProgressApprove && (
              <ProgressBar
                progress={progressApprove}
                wordUploader
                fileName={showApprovalFile}
                wordUpload
                onCancel={() => onCloseProgress()}
              />
            )}
          </Grid>
        )}
      </Grid>
      <Grid>
        <Grid>
          {downloadTemplate && (
            <Grid className="uploadBtnGrid">
              <p className="downloadBtn" style={{ border: '1px solid', borderColor: colors.pink.tertiary }}>
                <a href={templateUrl} download className="downloadLink">
                  Download Bulk Upload Template
                </a>
                <img src={CustomIcons.Download} alt="download" className="uploadImg" />
              </p>
            </Grid>
          )}
        </Grid>
        {excelUploader && (
          <Grid>
            <Grid className="uploadBtnGrid">
              <Grid className="uploadBtn">
                <label htmlFor="upload-file" className="uploadLabel">
                  Bulk Upload Template
                </label>
                <img src={CustomIcons.Upload} alt="upload" className="uploadImg" />
              </Grid>
              <input type="file" name="file" id="upload-file" className="input" onChange={(e) => onUploadFile(e)} />
            </Grid>
            {showProgressUpload && (
              <ProgressBar progress={progressUpload} fileName={showFileName} onCancel={() => onCloseProgress()} />
            )}
          </Grid>
        )}
      </Grid>

      <Grid className="footerBtn">
        {isSubmit && (
          <Grid className="fileBtn">
            <CustomButton
              variants="contained"
              btnTitle={masterSetup ? 'Submit' : 'Submit'}
              color="info"
              ellipse
              onClickHandle={() => onClickHandleSubmit()}
            />
          </Grid>
        )}
        {isSubmit !== true && (
          <Grid className="fileBtn">
            <CustomButton
              variants="contained"
              btnTitle={masterSetup ? 'View' : 'View'}
              color="quaternary"
              ellipse
              onClickHandle={() => onClickHandleView()}
            />
          </Grid>
        )}
        <Grid className="fileBtn">
          <CustomButton variants="contained" btnTitle="Cancel" color="disable" ellipse onClickHandle={onCancel} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileUpload;
FileUpload.propTypes = {
  downloadTemplate: PropTypes.bool,
  wordUploader: PropTypes.bool,
  excelUploader: PropTypes.bool,
  bulkUploader: PropTypes.bool,
  masterSetup: PropTypes.bool,
  sendSelectedFile: PropTypes.func,
  sendAttachment: PropTypes.func,
  templateUrl: PropTypes.string,
  onSubmitFiles: PropTypes.func,
  handleCancel: PropTypes.func,
  onClickHandleView: PropTypes.func,
  isSubmit: PropTypes.bool,
};
FileUpload.defaultProps = {
  downloadTemplate: false,
  wordUploader: false,
  excelUploader: false,
  bulkUploader: false,
  masterSetup: false,
  sendSelectedFile: () => null,
  sendAttachment: () => null,
  onSubmitFiles: () => null,
  handleCancel: () => null,
  templateUrl: '',
  onClickHandleView: () => null,
  isSubmit: null,
};

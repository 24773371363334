export const thData = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Program Activity',
  'Date Planned',
  'Date Completed',
  'Block Name',
  'Covered Population',
  'Total Population',
  'Eligible Population',
  'Test Type',
  'NBS Survey Date',
  'Site Name S',
  'BSE S',
  'Positive S',
  'Site Name R',
  'BSE R',
  'Positive R'
];
export const thDataDis = [
  'S.No',
  'State',
  'District',
  'Strategy',
  'Date Proposed',
  'Date Planned',
  'Date completed',
  'Covered Population',
  'Total Population',
  'Eligible Population',
  'Test Type',
  'NBS Survey Date', 
  'BlockName',
  'S1 Name',
  'BSE S1',
  'Positive S1',
  'BlockName',
  'R1 Name',
  'BSE R1',
  'Positive R1',
  'BlockName',
  'S2 Name',
  'BSE S2',
  'Positive S2',
  'BlockName',
  'R2 Name',
  'BSE R2',
  'Positive R2',
  'BlockName',
  'S3 Name',
  'BSE S3',
  'Positive S3',
  'BlockName',
  'R3 Name',
  'BSE R3',
  'Positive R3',
  'BlockName',
  'S4 Name',
  'BSE S4',
  'Positive S4',
  'BlockName',
  'R4 Name',
  'BSE R4',
  'Positive R4',
  'BlockName',
  'S5 Name',
  'BSE S5',
  'Positive S5',
  'BlockName',
  'R5 Name',
  'BSE R5',
  'Positive R5',
  'BlockName',
  'S6 Name',
  'BSE S6',
  'Positive S6',
  'BlockName',
  'R6 Name',
  'BSE R6',
  'Positive R6',
  'BlockName',
  'S7 Name',
  'BSE S7',
  'Positive S7',
  'BlockName',
  'R7 Name',
  'BSE R7',
  'Positive R7',
  'BlockName',
  'S8 Name',
  'BSE S8',
  'Positive S8',
  'BlockName',
  'R8 Name',
  'BSE R8',
  'Positive R8',
  'BlockName',
  'S9 Name',
  'BSE S9',
  'Positive S9',
  'BlockName',
  'R9 Name',
  'BSE R9',
  'Positive R9',
  'BlockName',
  'S10 Name',
  'BSE S10',
  'Positive S10',
  'BlockName',
  'R10 Name',
  'BSE R10',
  'Positive R10',
];
export const tdData = [
  {
    1: 1,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_No: 2,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_No: 3,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_No: 4,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
  {
    S_No: 5,
    State: 'Utter Pradesh',
    district: 'Kanpur',
    strategy: 'District',
    Date_Proposed: '09 Mar 2020',
    Date_Planned: '09 Jan 2020',
    Date_completed: '09 Mar 2020',
    covered_population: ' 20,00,000',
    population: '20,00,000',
    Test_type: 'NBS',
  },
];

import axios from 'axios';
import BaseUrl from '../../config/config';
import {
  getMdaIdaBlock,
  getMdaIdaDistrict,
  insertMdaIdaDistrictStrategy,
  updateMdaIda,
  getProposedDateIDA,
  updateMdaIdaById,
  getProposedDateDA,
  bulkInsertMdaIdaDistrict,
  bulkInsertMdaIdaBlock,
  // bulkUploadMdaIda,
  insertMdaIdaBlockStrategy,
} from '../../utils/api';

// console.log(userAurhId, 'userAurhId');
const userAurhId = localStorage.getItem('UserLoginId');
console.log(userAurhId, 'userAurhId');
/**
 * @param {object} data payload data
 * @name getIDARequest function which make api request to get IDA inputs data
 * @returns {*} returns axios response data
 */
export const getIDARequest = (data) => {
  console.log(data?.strategy, 'sdlkfjsrt');
  return axios.post(`${BaseUrl}/${data.strategy !== 'District Strategy' ? getMdaIdaBlock : getMdaIdaDistrict}`, {
    proposed_program_activities: data.proposed_program_activities,
    strategy: data.strategy,
  });
};
/**
 * @param {object} data payload data
 * @name insertIDARequest function which make api request to add ida
 * @returns {*} returns axios response data
 */
export const insertIDARequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertMdaIdaDistrictStrategy}`,
    data: {
      program_activity: data?.activity,
      state_id: data?.state,
      district_id: data?.district,
      strategy_id: data?.strategy === 'District Strategy' ? 2 : 1,
      date_proposed: data?.date_proposed_by_nvbdcp,
      date_planned: data?.date_planned_by_state,
      date_completed: data?.mda_date_completed,
      covered_population: data?.covered_population,
      population: data?.total_population1,
      eligible_population: data?.eligible_population,
      sentinal: data.sentinal,
      random: data.random,
      test_type_id: data?.test_type?? data?.Test_Type,
      nbs_survey_date: data?.nbs_survey_date,
      coverage: data?.coverage,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name insertMdaBlockRequest function which make api request to add ida
 * @returns {*} returns axios response data
 */
export const insertMdaBlockRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${insertMdaIdaBlockStrategy}`,
    data: {
      program_activity: data?.activity,
      state_id: data?.state,
      district_id: data?.district,
      strategy_id: 1,
      date_proposed: data?.date_proposed_by_nvbdcp,
      date_planned: data?.date_planned_by_state,
      date_completed: data?.mda_date_completed,
      covered_population: data?.covered_population,
      population: data?.total_population1,
      eligible_population: data?.eligible_Population,
      block_count: data?.block_count,
      coverage: data?.coverage,
      created_by: userAurhId && userAurhId,
    },
  });
};

/**
 * @param {object} data payload data
 * @name updateIDARequest function which make api request to update ida
 * @returns {*} returns axios response data
 */
export const updateIDARequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateMdaIda}`,
    data: {
      overAllMdaUpdate: data,
      updated_by: userAurhId && userAurhId,
    },
  });
};
/**
 * @param {*} root root
 * @param {object} root.params payload data
 * @param {string} root.type type type
 * @name getProposedDateIDARequest function which make api request to add ida
 * @returns {*} returns axios response data
 */
export const getProposedDateIDARequest = ({ params, type }) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${type === 'IDA' ? getProposedDateIDA : getProposedDateDA}`,
    data: {
      state: params.state,
      district: params.district,
      strategy: params.strategy === `Block Strategy` ? 1 : 2,
      superuser: userAurhId && userAurhId,
    },
  });
};
/**
 * @param {object} data payload data
 * @name updateIDAByIdRequest function which make api request to update ida by id
 * @returns {*} returns axios response data
 */
export const updateIDAByIdRequest = (data) => {
  return axios({
    method: 'put',
    url: `${BaseUrl}/${updateMdaIdaById}`,
    data: {
      proposed_program_activities: data.activity,
      state_id: data.State,
      district_id: data.District,
      block_strategy_id: data.Strategy,
      sentinal: data.sentinal,
      random: data.random,
      date_proposed: data.Date_Proposed || null,
      date_planned: data.Date_Planned || null,
      date_completed: data.Date_Completed || null,
      nbs_date_completed: data.nbs_date_completed || null,
      coverd_population: data.Covered_Population,
      population: data.Population,
      MDA_IDA_id: data.MDA_IDA_id,
      mdaIdaSiteBlock: data.Sites,
      updated_by: userAurhId && userAurhId,
    },
  });
};

/**
 *
 * @param  {object} data payload data
 * @returns {*} returns axios response data
 */

export const bulkUploadMdaIdaRequest = (data) => {
  return axios({
    method: 'post',
    url: `${BaseUrl}/${
      data[0].strategy_id.toLowerCase() === 'block strategy'
        ? bulkInsertMdaIdaBlock
        : bulkInsertMdaIdaDistrict
    }`,
    data: {
      bulk_insert_mda: data,
      created_by: userAurhId && userAurhId,
    },
  });
};


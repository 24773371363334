import { takeLatest } from 'redux-saga/effects';
import {
  GET_DRUG,
  INSERT_DRUG_MASTERS,
  GET_DRUG_REGIME,
  GET_DRUG_TYPE,
  UPDATE_DRUG,
  BULK_UPLOAD_DRUG,
  INSERT_DISTRICT_MASTERS,
  GET_DISTRICT,
  UPDATE_DISTRICT_MASTERS,
  INSERT_STATE_MASTERS,
  GET_STATE,
  UPDATE_STATE_MASTERS,
} from '../../constants/actionType/index';
import {
  getDrugHandler,
  insertDrugMastersHandler,
  getDrugRegimeHandler,
  getDrugTypeHandler,
  updateDrugHandler,
  bulkUploadDrugHandler,
  insertDistrictMastersHandler,
  getDistrictHandler,
  updateDistrictMastersHandler,
  insertStateMastersHandler,
  getStateHandler,
  updateStateMastersHandler,
} from '../handler/drugTypeHandler';

/**
 * @name nationalBoardSaga used for dashboard api call
 * @yields nationalPretasOverviewHandler
 */
function* DrugTypeSaga() {
  yield takeLatest(GET_DRUG, getDrugHandler);
  yield takeLatest(INSERT_DRUG_MASTERS, insertDrugMastersHandler);
  yield takeLatest(GET_DRUG_REGIME, getDrugRegimeHandler);
  yield takeLatest(GET_DRUG_TYPE, getDrugTypeHandler);
  yield takeLatest(UPDATE_DRUG, updateDrugHandler);
  yield takeLatest(BULK_UPLOAD_DRUG, bulkUploadDrugHandler);
  yield takeLatest(GET_DISTRICT, getDistrictHandler);
  yield takeLatest(INSERT_DISTRICT_MASTERS, insertDistrictMastersHandler);
  yield takeLatest(UPDATE_DISTRICT_MASTERS, updateDistrictMastersHandler);
  yield takeLatest(GET_STATE, getStateHandler);
  yield takeLatest(INSERT_STATE_MASTERS, insertStateMastersHandler);
  yield takeLatest(UPDATE_STATE_MASTERS, updateStateMastersHandler);
}
export default DrugTypeSaga;
